.root {
  overflow: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hiddenScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hiddenScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

//
.nc-will-change-transform {
  will-change: transform;
}
.nc-will-change-top {
  will-change: top;
}

//
.nc-card-title {
  @apply transition-colors  hover:text-primary-900 dark:hover:text-primary-300 duration-75;
}

//
.z-max {
  z-index: 99999999;
}
//
.nc-PostCardCommentBtn,
.nc-PostCardLikeAction,
.nc-CommentCardLikeReply > button {
  &:hover {
    span {
      color: inherit;
    }
  }
}

//
.nc-box-has-hover {
  @apply bg-white rounded-3xl border border-neutral-200 border-opacity-70 hover:bg-neutral-50 hover:shadow-xl hover:border-transparent transition-shadow;
}

.nc-dark-box-bg-has-hover {
  @apply dark:bg-neutral-800 dark:bg-opacity-30  dark:border dark:border-neutral-700 dark:hover:bg-neutral-800 dark:hover:shadow-2xl;
}

.nc-dark-box-bg {
  @apply dark:bg-neutral-800 dark:bg-opacity-30  dark:border dark:border-neutral-700 dark:shadow-2xl;
}

//
.nc-origin-100 {
  transform-origin: 100% 50% 0px;
}
.nc-origin-50 {
  transform-origin: 50% 50% 0px;
}

//
.nc-PostFeaturedMedia {
  .glide__bullet--active {
    @apply bg-white bg-opacity-100;
  }
}

.modalPhotos-single-gallery {
  .controls_nav {
    .glide__bullet--active {
      display: block;
    }
  }
}

// LOADING
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

//

.nc-animation-spin {
  animation: myspin 20s linear infinite;
  animation-play-state: paused;

  &.playing {
    animation-play-state: running;
  }

  @keyframes myspin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

[type='button'] {
  -webkit-appearance: none !important;
}

//
.nc-section-rounded-none {
  *[class*='rounded'],
  *[class*='nc-box'] {
    border-radius: 0 !important;
  }
}

.nc-section-rounded-md {
  *[class*='rounded'],
  *[class*='nc-box'] {
    @apply rounded-md #{!important};
  }
}
